[data-component="App"] {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("./../public/fondo_logos.png") no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
    @media (max-width: 768px) {
      animation: bg-animation 3s ease-out infinite alternate both;
      background-position: 80% 0px;
    }
  }
}

.bg-animation {
  -webkit-animation: bg-animation 3s ease-out infinite alternate both;
  animation: bg-animation 3s ease-out infinite alternate both;
}

/**
 * ----------------------------------------
 * animation bg-animation
 * ----------------------------------------
 */
@-webkit-keyframes bg-animation {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes bg-animation {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
