html,
body {
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Galano Grotesque', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #18181b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Galano Grotesque';
  src: url('./fonts/GalanoGrotesque-Medium.eot');
  src: url('./fonts/GalanoGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GalanoGrotesque-Medium.woff2') format('woff2'),
    url('./fonts/GalanoGrotesque-Medium.woff') format('woff'),
    url('./fonts/GalanoGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Galano Grotesque';
  src: url('./fonts/GalanoGrotesque-Bold.eot');
  src: url('./fonts/GalanoGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GalanoGrotesque-Bold.woff2') format('woff2'),
    url('./fonts/GalanoGrotesque-Bold.woff') format('woff'),
    url('./fonts/GalanoGrotesque-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Galano Grotesque';
  src: url('./fonts/GalanoGrotesque-Light.eot');
  src: url('./fonts/GalanoGrotesque-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GalanoGrotesque-Light.woff2') format('woff2'),
    url('./fonts/GalanoGrotesque-Light.woff') format('woff'),
    url('./fonts/GalanoGrotesque-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}