:root {
  --padding-top: 8px;
  --padding-right: 16px;
  --padding-bottom: 8px;
  --padding-left: 16px;
  --icon-size: 40px;
  --icon-border-radius: 50%;
  --icon-gap: 16px;
  --border-radius: 1000px;
  --font-size: 18px;
  --font-color: #000000;
  --font-family: "Galano Grotesque", sans-serif;
  --bg-color: #f8f8f8;
  --button-min-height: calc(var(--icon-size) + var(--padding-top) + var(--padding-bottom));
}
@media (max-width: 768px) {
  :root {
    --icon-size: 32px;
    --icon-gap: 12px;
    --font-size: 14px;
  }
}

[data-component="Button"] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--icon-gap);
  font-size: var(--font-size);
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  border: none;
  background-color: var(--bg-color);
  color: var(--font-color);
  border-radius: var(--border-radius);
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
  font-family: var(--font-family);
  min-height: var(--button-min-height);
  transition:
    transform 0.1s ease-in-out,
    background-color 0.1s ease-in-out;
  outline: none;
  box-shadow: -2px 4px 5px 0px #0000000f;

  &.button-style-alert {
    background-color: #7fbc0042;
    color: #141414;

    @media screen and (max-width: 768px) {
      .label {
        pointer-events: none;
      }
    }

    &:hover:not([disabled]) {
      background-color: #7fbc0042;
      color: #141414;
    }
  }

  &.button-style-flush {
    background-color: rgba(255, 255, 255, 0);
  }

  &[disabled] {
    color: #999;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    background-color: #d8d8d8;
  }

  &:active:not([disabled]) {
    transform: translateY(1px);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    min-width: var(--icon-size);
    min-height: var(--icon-size);

    &.icon-start {
      margin-left: -8px;
      background-color: #7fbc00;
      color: #fff;
    }
    &.icon-end {
      margin-right: -8px;
      background-color: #e1251b;
      color: #fff;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-red .icon {
    color: #e1251b;
  }
  &.icon-green .icon {
    color: #009900;
  }
  &.icon-grey .icon {
    color: #909090;
  }
  &.icon-fill {
    svg {
      fill: currentColor;
    }
  }
}
