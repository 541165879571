[data-component="ConsolePage"] {
  // font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0px 8px;
  max-width: 1024px;
  width: 100%;
  margin: auto;
  & > div {
    flex-shrink: 0;
  }

  .spacer {
    flex-grow: 1;
  }

  .content-top {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 40px;
    display: none;
    .content-title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .content-main {
    flex-grow: 1;
    flex-shrink: 1 !important;
    margin: 0px 16px;
    display: flex;
    overflow: hidden;

    .content-block {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      width: 100%;
      &-label {
        color: #000;
        background: #f1f1f1;
        text-align: center;
        padding: 32px 20px;
        p {
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 17px;
        }
      }
      .content-block-title {
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 300;
        position: relative;
        text-align: center;
        color: #b8b8b8;
        margin: 26px 0;
      }
      .content-block-body {
        color: #6e6e7f;
        position: relative;
        top: 40px;
        flex-grow: 1;
        padding: 8px 0px;
        padding-top: 4px;
        line-height: 1.2em;
        overflow: auto;
        height: 100%;
        max-height: calc(100vh - 300px);
        scroll-behavior: smooth;

        &.full {
          padding: 0px;
        }
      }
    }
    .content-right {
      width: 300px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      gap: 24px;
      & > div {
        border-radius: 16px;
        flex-grow: 1;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        .content-block-title {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 2em;
          top: 16px;
          left: 16px;
          padding: 4px 16px;
          background-color: #fff;
          border-radius: 1000px;
          min-height: 32px;
          z-index: 9999;
          text-align: center;
          white-space: pre;
          &.bottom {
            top: auto;
            bottom: 16px;
            right: 16px;
          }
        }
      }
      & > div.kv {
        height: 250px;
        max-height: 250px;
        white-space: pre;
        background-color: #ececf1;
        .content-block-body {
          padding: 16px;
          margin-top: 56px;
        }
      }
    }
    .content-logs {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      & > div {
        flex-grow: 1;
      }
      & > .content-actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
      }
      & > div.events {
        overflow: hidden;
      }
      .events {
        border-top: 1px solid #e7e7e7;
      }
      .conversation {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;
        height: 200px;
        min-height: 0;
        max-height: 100dvh;
        border-top: 1px solid #e7e7e7;
      }
    }
  }
  .content-actions {
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    right: 0;
    margin: 0 auto;
  }
  .conversation-item {
    position: relative;
    display: flex;
    gap: 16px;
    padding: 0 16px;
    margin-bottom: 16px;
    &:not(:hover) .close {
      display: none;
    }
    &:first-of-type {
      display: none;
    }
    .close {
      position: absolute;
      top: 0px;
      right: -20px;
      background: #aaa;
      color: #fff;
      display: flex;
      border-radius: 16px;
      padding: 2px;
      cursor: pointer;
      display: none;
      &:hover {
        background: #696969;
      }
      svg {
        stroke-width: 3;
        width: 12px;
        height: 12px;
      }
    }
    .speaker {
      position: relative;
      text-align: left;
      gap: 16px;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      &-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
        border: 1px solid #e7e7e7;
      }
      &.user {
        color: #0099ff;
      }
      &.assistant {
        color: #009900;
      }
    }
    .speaker-content {
      color: #18181b;
      overflow: hidden;
      word-wrap: break-word;
      width: 100%;

      > div {
        margin-bottom: 8px;
        display: none;
      }
      audio {
        width: 100%;
      }
    }
  }

  .event {
    border-radius: 3px;
    white-space: pre;
    display: flex;
    padding: 0px;
    gap: 16px;
    .event-timestamp {
      text-align: left;
      gap: 8px;
      padding: 4px 0px;
      width: 80px;
      flex-shrink: 0;
      margin-right: 16px;
    }
    .event-details {
      display: flex;
      flex-direction: column;
      color: #18181b;
      gap: 8px;
      .event-summary {
        padding: 4px 8px;
        margin: 0px -8px;
        &:hover {
          border-radius: 8px;
          background-color: #f0f0f0;
        }
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        .event-source {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          gap: 8px;
          &.client {
            color: #0099ff;
          }
          &.server {
            color: #009900;
          }
          &.error {
            color: #990000;
          }
          svg {
            stroke-width: 3;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .visualization {
    position: absolute;
    display: flex;
    bottom: 4px;
    right: 8px;
    padding: 4px;
    border-radius: 16px;
    z-index: 10;
    gap: 2px;
    .visualization-entry {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      width: 100px;
      gap: 4px;
      &.client {
        color: #0099ff;
      }
      &.server {
        color: #009900;
      }
      canvas {
        width: 100%;
        height: 100%;
        color: currentColor;
      }
    }
  }
  .mockup-interface {
    width: 100%;
    .mockup-phone {
      position: relative;
      margin: 0 auto;
      width: auto;
      height: auto;
      max-width: 50%;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
    }
  }
  .image-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.page-title {
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  color: #18181b;
}

.iphone-x {
  position: relative;
  margin: 40px auto;
  width: 88%;
  max-width: 420px;
  height: 100dvh;
  overflow: hidden;
  background-color: #fff;
  border-radius: 40px;
  box-shadow:
    0px 0px 0px 11px #1f1f1f,
    0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // home button indicator
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }

  // frontal camera/speaker frame
  &:before {
    top: 0px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
    z-index: 1;
  }

  i,
  b,
  s,
  span {
    position: absolute;
    display: block;
    color: transparent;
  }

  // speaker
  i {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
    z-index: 2;
  }

  // camera
  b {
    left: 10%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // time
  s {
    top: 50px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 100%;
    font-size: 70px;
    font-weight: 100;
    padding-top: 60px;
  }
}

.unlock-bg {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #7371ee 1%, #a1d9d6 100%);
  transition: top 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0;
}

.iphone-x.unlocked .unlock-bg {
  top: 0;
}
.pdf-modal {
  overflow: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    z-index: 9999;
    background-color: #18181b;
    border-radius: 50px;
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pdf-previsualizer {
  // border-radius: 28px;
  // border: 2px solid #000;
  width: 100%;
  max-width: 420px;
  height: 70vh;
  margin-left: auto;
  .pdf-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
